.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

.overflow-rules{
  overflow: auto;
  height: 90vh;
}

@media only screen and (max-width: 767px) {
  .overflow-rules{
    height: auto;
  }
}