.AboutMe {
    border-width: 2px;
    border-style: solid;
    border-color: #5d5d5d;
    min-height: 90vh;
    border-radius: 30px;
    padding: calc(10px + 3vmin);
}

@media only screen and (max-width: 767px) {
    .AboutMe {
        height: auto;
        margin-top: 12px;
    }
}

.about-me-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.profile-name {
    font-weight: 600;
    font-size: calc(10px + 3vmin);
}

.profile-title {
    align-self: flex-end;
    font-size: calc(10px + 1vmin);
}

.mugshot {
    width: 100%;
    border-radius: 30px;
    margin: 10% 0 10% 0;
}

.email-loc {
    font-size: calc(10px + .5vmin);
}

.icon-boarder {
    padding: 12px;
    border-width: 2px;
    border-color: #5d5d5d;
    border-style: solid;
    width: auto;
    margin: 10px;
    border-radius: 50px;
    color: white;
}

.icon-boarder:hover {
    border-color: #2be88d;
    color: #2be88d;
}

a {
    color: white;
}

a:hover {
    color: white;
}