.skills-title{
    font-size: calc(10px + 5vmin);
    letter-spacing: calc(1px + .5vmin);
    font-weight: 500;
}

.skills-section-title{
    font-size: calc(10px + 2vmin);
    letter-spacing: calc(1px + .3vmin);
    color: #6ae4a9;
}

.skill-icon{
    color: #1f1f21;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s ease-in-out;
}
    .skill-icon:hover{
        color: #6ae4a9!important;
        border-color: #6ae4a9!important;
    }

.icon-border {
        padding: 12px;
        border-width: 2px;
        border-color: #5d5d5d;
        border-style: solid;
        width: auto;
        margin: 10px;
        border-radius: 50px;
        color: white;
        transition: 0.2s ease-in-out;
    }
    .icon-border:hover {
        border-color: #2be88d;
        color: #2be88d;
    }