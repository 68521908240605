.landing-title{
    font-size: calc(10px + 6vmin);
    font-weight: 500;
    text-align-last: right;
    margin-right: 6%;
}

.landing-subtitle{
    color: #999999;
}

.landing-about-txt-div{
    width: 60%;
    margin-left: '10px';
}

@media only screen and (max-width: 764px) {
    .landing-about-txt-div{
        width: 100%;
    }
}